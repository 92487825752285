import { useEffect, useRef } from "react";
import { useRouter } from "next/navigation";
import type { NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";

export const useBeforeunload = (
  shouldBlockFn: () => boolean,
  errorMessage: string,
) => {
  const router = useRouter();

  // Persist fn in ref
  const shouldBlockFnRef = useRef(shouldBlockFn);
  useEffect(() => {
    shouldBlockFnRef.current = shouldBlockFn;
  });

  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      const shouldBlock = shouldBlockFnRef.current();

      if (shouldBlock) {
        event.preventDefault();
        // Handle legacy `event.returnValue` and `return` activation.
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
        return (event.returnValue = "");
      }
    };

    // https://github.com/vercel/next.js/discussions/41934#discussioncomment-8174608
    const originalPush = router.push;
    router.push = (
      href: string,
      options?: NavigateOptions | undefined,
    ): void => {
      const shouldBlock = shouldBlockFnRef.current();

      const isConfirmed = !shouldBlock || window.confirm(errorMessage);

      if (isConfirmed) {
        originalPush(href, options);
      }
    };

    window.addEventListener("beforeunload", listener);
    return () => {
      router.push = originalPush;
      window.removeEventListener("beforeunload", listener);
    };
  }, [router]);
};
