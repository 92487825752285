"use client";

import {
  CourseContext_courseAcademicTerm$data,
  CourseContext_courseAcademicTerm$key,
} from "@generated/CourseContext_courseAcademicTerm.graphql";
import { createContext, useContext, useRef } from "react";
import { graphql, useFragment } from "react-relay";
import { createStore, useStore } from "zustand";

interface Props {
  courseAcademicTerm: CourseContext_courseAcademicTerm$key;
}

const CourseContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  courseAcademicTerm,
}) => {
  const data = useFragment(
    graphql`
      fragment CourseContext_courseAcademicTerm on CourseAcademicTerm {
        id
        slug
        academicTerm {
          id
          startDate
          label
          endDate
        }
        course {
          id
          slug
          title
        }
        isCurrentAcademicTerm
        sections {
          items {
            id
            title
            slug
            __typename
          }
        }
        effectiveUiSettings {
          courseContentsSide
        }
      }
    `,
    courseAcademicTerm,
  );

  const storeRef = useRef<CourseStore>();

  if (!storeRef.current) {
    storeRef.current = createCourseStore({ courseAcademicTerm: data });
  }

  return (
    <CourseStoreContext.Provider value={storeRef.current}>
      {children}
    </CourseStoreContext.Provider>
  );
};

interface CourseProps {
  courseAcademicTerm: CourseContext_courseAcademicTerm$data;
}

interface CourseState extends CourseProps {
  currentItem: { id: string } | null;
  setCurrentItem: (item: { id: string } | null) => void;
}

const createCourseStore = ({ courseAcademicTerm }: CourseProps) => {
  return createStore<CourseState>()((set, get) => ({
    courseAcademicTerm,
    currentItem: null,
    setCurrentItem: (item) => set({ currentItem: item }),
  }));
};

type CourseStore = ReturnType<typeof createCourseStore>;

const CourseStoreContext = createContext<CourseStore | null>(null);

export const useCourseAcademicTerm = () => {
  const context = useContext(CourseStoreContext);

  if (!context) {
    throw new Error("No course data set, use CourseContextProvider to set one");
  }

  return useStore(context, (state) => state.courseAcademicTerm);
};

export const useCurrentItem = () => {
  const context = useContext(CourseStoreContext);

  if (!context) {
    throw new Error(
      "No current item data set, make sure you are using CourseContextProvider",
    );
  }

  return useStore(context, (state) => state.currentItem);
};

export const useSetCurrentItem = () => {
  const context = useContext(CourseStoreContext);

  if (!context) {
    throw new Error(
      "No current item data set, make sure you are using CourseContextProvider",
    );
  }

  return useStore(context, (state) => state.setCurrentItem);
};

export const useNextItem = () => {
  const course = useCourseAcademicTerm();
  const currentItem = useCurrentItem();

  if (!currentItem) {
    return null;
  }

  const sectionIdx = course.sections.findIndex((section) => {
    return section.items.some((item) => {
      return item.id === currentItem.id;
    });
  });

  const section = course.sections[sectionIdx];

  if (!section) {
    return null;
  }

  const itemIdx = section.items.findIndex((item) => {
    return item.id === currentItem.id;
  });

  if (itemIdx === -1) {
    return null;
  }

  // if current item is the last item of the section, move to next sections
  if (itemIdx === section.items.length - 1) {
    let i = 1;
    let nextSection = course.sections[sectionIdx + i];

    // handle empty sections
    while (nextSection && nextSection.items.length === 0) {
      i += 1;
      nextSection = course.sections[sectionIdx + i];
    }

    return nextSection?.items[0] ?? null;
  }

  return section.items[itemIdx + 1] ?? null;
};

export default CourseContextProvider;
