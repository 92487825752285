/**
 * @generated SignedSource<<a83a47f91fcf8df1bc2e90a587928027>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ItemProgressStatus = "COMPLETED" | "IN_PROGRESS" | "%future added value";
export type UpdateCourseItemProgressInput = {
  completed?: boolean | null | undefined;
  itemId: string;
  progress?: number | null | undefined;
};
export type useUpdateItemProgressMutation$variables = {
  input: UpdateCourseItemProgressInput;
};
export type useUpdateItemProgressMutation$data = {
  readonly updateCourseItemProgress: {
    readonly progress: {
      readonly firstProgressAt: string;
      readonly progress: number;
      readonly status: ItemProgressStatus;
      readonly updatedAt: string;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type useUpdateItemProgressMutation = {
  response: useUpdateItemProgressMutation$data;
  variables: useUpdateItemProgressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCourseItemProgressPayload",
    "kind": "LinkedField",
    "name": "updateCourseItemProgress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseItemProgress",
        "kind": "LinkedField",
        "name": "progress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "progress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstProgressAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "userErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateItemProgressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateItemProgressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb1202c0f607d9b2c3d43fcf705501ae",
    "id": null,
    "metadata": {},
    "name": "useUpdateItemProgressMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateItemProgressMutation(\n  $input: UpdateCourseItemProgressInput!\n) {\n  updateCourseItemProgress(input: $input) {\n    progress {\n      progress\n      status\n      firstProgressAt\n      updatedAt\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee631b71cc838d4726f5c0a422d9c2f2";

export default node;
