import { format, parseISO } from "date-fns";
import { tr } from "date-fns/locale";
import { ok } from "assert";

export function formatDateToRelative(
  dateStr: string,
  includeHours = true,
): string {
  const date = parseISO(dateStr);

  return format(date, includeHours ? "PPpp" : "PP", { locale: tr });
}

export function durationToSeconds(duration: string): number {
  const [d1, d2, d3] = duration.split(":").map(Number);

  console.log(d1, d2, d3);

  ok(d1 !== undefined && !isNaN(d1) && d2 !== undefined && !isNaN(d2));

  if (d3 === undefined || isNaN(d3)) {
    // Only minutes and seconds
    return d1 * 60 + d2;
  }

  return d1 * 3_600 + d2 * 60 + d3;
}

export function msToDuration(ms: number): string {
  const hours = Math.floor(ms / 3_600_000);
  const minutes = Math.floor((ms % 3_600_000) / 60_000);
  const seconds = Math.floor((ms % 60_000) / 1_000);

  if (hours === 0) {
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}

export function secondsToDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3_600);
  const minutes = Math.floor((seconds % 3_600) / 60);
  const secs = seconds % 60;

  if (hours === 0) {
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  }

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
}
