/**
 * @generated SignedSource<<9c20e26dbe1f5a19579fc87074a89691>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type CourseContentsSide = "LEFT" | "RIGHT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type CourseContext_courseAcademicTerm$data = {
  readonly academicTerm: {
    readonly endDate: string | null | undefined;
    readonly id: string;
    readonly label: string;
    readonly startDate: string | null | undefined;
  } | null | undefined;
  readonly course: {
    readonly id: string;
    readonly slug: string;
    readonly title: string;
  };
  readonly effectiveUiSettings: {
    readonly courseContentsSide: CourseContentsSide;
  };
  readonly id: string;
  readonly isCurrentAcademicTerm: boolean;
  readonly sections: ReadonlyArray<{
    readonly items: ReadonlyArray<{
      readonly __typename: string;
      readonly id: string;
      readonly slug: string;
      readonly title: string;
    }>;
  }>;
  readonly slug: string;
  readonly " $fragmentType": "CourseContext_courseAcademicTerm";
};
export type CourseContext_courseAcademicTerm$key = {
  readonly " $data"?: CourseContext_courseAcademicTerm$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseContext_courseAcademicTerm">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseContext_courseAcademicTerm",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AcademicTerm",
      "kind": "LinkedField",
      "name": "academicTerm",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentAcademicTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseSection",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EffectiveCourseAcademicTermUISettings",
      "kind": "LinkedField",
      "name": "effectiveUiSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseContentsSide",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseAcademicTerm",
  "abstractKey": null
};
})();

(node as any).hash = "8f66604df78198745802024c403083a8";

export default node;
