const slashRE = /^\//;
const protocolAndDomainRE = /^(?:(?:\w+:\/\/)|(?:\/\/))?(\S+)$/;
const emailLintRE = /mailto|tel:([^?\\]+)/;

const localhostDomainRE = /^localhost[\d:?]*(?:[^\d:?]\S*)?/;
const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}/;

/**
 * Loosely validate a URL `str`. we also accept /link
 */
export const isUrl = (str: unknown) => {
  if (typeof str !== "string") {
    return false;
  }

  if (str.match(slashRE) || str.match(emailLintRE)) {
    return true;
  }

  const match = str.match(protocolAndDomainRE);

  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  return (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  );
};
