"use client";

import { z } from "zod";
import { graphql, useFragment, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSubmitButton,
} from "@/components/ui/form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import React, { useCallback, useState } from "react";
import { Input } from "@/components/ui/input";
import { useBeforeunload } from "@/lib/hooks/useBeforeunload";
import { Button } from "@/components/ui/button";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { UpdateAttachmentDialogUpdateAttachmentMutation } from "@generated/UpdateAttachmentDialogUpdateAttachmentMutation.graphql";
import { UpdateAttachmentDialog_attachment$key } from "@generated/UpdateAttachmentDialog_attachment.graphql";

const getInputSchema = (fileNameExtension: string | undefined) =>
  z.object({
    title: z
      .string({ required_error: "Lütfen bir ek adı gir." })
      .min(1, {
        message: "Lütfen bir ek adı gir.",
      })
      .max(200, {
        message: "Ek adı maksimum 200 karakter içerebilir.",
      }),
    fileName: z
      .string({ required_error: "Lütfen bir dosya adı gir." })
      .min(1, {
        message: "Lütfen bir dosya adı gir.",
      })
      .regex(/^[^\\{}^%`[\]">~<#|]+$/, {
        message:
          "Dosya adı geçersiz karakterler içeriyor. Lütfen geçersiz karakterleri kaldır.",
      })
      .max(200, {
        message: "Dosya adı maksimum 200 karakter içerebilir.",
      })
      .refine(
        (value) =>
          fileNameExtension
            ? value.endsWith("." + fileNameExtension)
            : value.indexOf(".") === -1,
        {
          message: fileNameExtension
            ? `Dosya adı uzantısı ${fileNameExtension} olmalıdır.`
            : "Dosya adı nokta içermemelidir",
        },
      ),
  });

const UpdateAttachmentDialog: React.FC<{
  attachment: UpdateAttachmentDialog_attachment$key;
}> = ({ attachment }) => {
  const data = useFragment(
    graphql`
      fragment UpdateAttachmentDialog_attachment on Attachment {
        id
        title
        fileName
      }
    `,
    attachment,
  );

  const fileNameExtension = data.fileName.split(".").pop();

  const [inputSchema] = React.useMemo(() => {
    return [getInputSchema(fileNameExtension)];
  }, [fileNameExtension]);

  const form = useForm({
    resolver: zodResolver(inputSchema),
    mode: "onTouched",
    defaultValues: {
      title: data.title,
      fileName: data.fileName,
    },
  });

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const onAttachmentUpdated = useCallback(() => {
    setUpdateDialogOpen(false);
  }, []);

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const onCancelDialogOpenChange = useCallback(() => {
    setCancelDialogOpen(false);
  }, []);

  const shouldBlockNavigation = useCallback(() => {
    if (form.getValues("title") !== data.title) {
      return true;
    }
    if (form.getValues("fileName") !== data.fileName) {
      return true;
    }
    return false;
  }, [form, data.title, data.fileName]);

  const onUpdateDialogOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        setUpdateDialogOpen(true);
      } else if (shouldBlockNavigation()) {
        setCancelDialogOpen(true);
      } else {
        setUpdateDialogOpen(false);
      }
    },
    [shouldBlockNavigation],
  );

  useBeforeunload(
    () => shouldBlockNavigation(),
    "Kaydetmediğiniz değişiklikler var, bu sayfadan ayrılmak istediğinize emin misiniz?",
  );

  const [commitUpdateAttachment, isUpdateAttachmentInFlight] =
    useMutation<UpdateAttachmentDialogUpdateAttachmentMutation>(graphql`
      mutation UpdateAttachmentDialogUpdateAttachmentMutation(
        $input: UpdateAttachmentInput!
      ) {
        updateAttachment(input: $input) {
          attachment {
            ...CourseAdminAttachment_attachment
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  return (
    <>
      <Dialog
        open={cancelDialogOpen}
        onOpenChange={onCancelDialogOpenChange}
        modal
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Kaydedilmemiş değişiklikleriniz var</DialogTitle>
            <DialogDescription>
              Değişikliklerinizi kaydetmeden çıkmak istediğinizden emin misiniz?
            </DialogDescription>
          </DialogHeader>
          <Button variant="outline" onClick={() => setCancelDialogOpen(false)}>
            Düzenlemeye devam et
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              setCancelDialogOpen(false);
              setUpdateDialogOpen(false);
            }}
          >
            Değişiiklikleri kaydetmeden çık
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={updateDialogOpen}
        onOpenChange={onUpdateDialogOpenChange}
        modal
      >
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            onClick={(e) => {
              // Prevent the accordion from opening/closing
              e.stopPropagation();
            }}
            asChild
          >
            <span>
              <span className="sr-only">Edit</span>
              <Pencil1Icon />
            </span>
          </Button>
        </DialogTrigger>
        <Form {...form}>
          <DialogContent className="sm:max-w-5xl">
            <form
              onSubmit={form.handleSubmit((values) => {
                if (isUpdateAttachmentInFlight) {
                  return;
                }

                if (
                  values.title === data.title &&
                  values.fileName === data.fileName
                ) {
                  onAttachmentUpdated();
                  return;
                }

                commitUpdateAttachment({
                  variables: {
                    input: {
                      attachmentId: data.id,
                      title:
                        values.title !== data.title
                          ? { value: values.title }
                          : undefined,
                      fileName:
                        values.fileName !== data.fileName
                          ? { value: values.fileName }
                          : undefined,
                    },
                  },
                  onCompleted: (data) => {
                    if (data.updateAttachment.userErrors.length > 0) {
                      data.updateAttachment.userErrors.forEach((error) => {
                        // field is in the form of ["input", "title"]
                        const field = error.field[1];

                        if (field === "title" || field === "fileName") {
                          form.setError(field, {
                            type: "manual",
                            message: error.message,
                          });
                        } else {
                          console.error("Unexpected error:", error);
                        }
                      });

                      return;
                    }
                    onAttachmentUpdated();
                  },
                });
              })}
            >
              <DialogHeader>
                <DialogTitle>Eki Güncelle</DialogTitle>
                <DialogDescription></DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Ek Adı</FormLabel>
                      <FormControl>
                        <Input placeholder="Ek Adı" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="fileName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Dosya Adı</FormLabel>
                      <FormControl>
                        <Input placeholder="Dosya Adı" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <DialogFooter>
                <FormSubmitButton
                  loading={isUpdateAttachmentInFlight}
                  className="w-full"
                >
                  Eki Güncelle
                </FormSubmitButton>
              </DialogFooter>
            </form>
          </DialogContent>
        </Form>
      </Dialog>
    </>
  );
};

export default UpdateAttachmentDialog;
