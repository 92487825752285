import { Badge } from "@/components/ui/badge";
import {
  CalendarIcon,
  CodeIcon,
  CounterClockwiseClockIcon,
  DownloadIcon,
  LapTimerIcon,
  MixIcon,
  Pencil2Icon,
  ReaderIcon,
  VideoIcon,
} from "@radix-ui/react-icons";
import { RadioIcon } from "lucide-react";

interface Props {
  type: string;
  subtype?: string;
  className?: string;
}

const ItemTypeBadge: React.FC<Props> = ({ type, subtype, className }) => {
  if (type === "VideoCourseItem" && subtype === "LECTURE") {
    return (
      <Badge variant="outline" className={className}>
        <VideoIcon className="mr-1 size-4" />
        Konu
        <span className="hidden whitespace-pre sm:inline"> Anlatımı</span>
      </Badge>
    );
  }

  if (type === "VideoCourseItem" && subtype === "REVIEW") {
    return (
      <Badge variant="outline" className={className}>
        <CounterClockwiseClockIcon className="mr-1 size-4" />
        <span className="hidden whitespace-pre sm:inline">Konu </span>
        Tekrar<span className="hidden whitespace-pre sm:inline">ı</span>
      </Badge>
    );
  }

  if (type === "QuestionCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <Pencil2Icon className="mr-1 size-4" />
        Soru
        <span className="hidden whitespace-pre sm:inline"> Çözümü</span>
      </Badge>
    );
  }

  if (type === "QuizCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <MixIcon className="mr-1 size-4" />
        Quiz
      </Badge>
    );
  }

  if (type === "ExamCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <LapTimerIcon className="mr-1 size-4" />
        Sınav
      </Badge>
    );
  }

  if (type === "AssignmentCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <CalendarIcon className="mr-1 size-4" />
        Ödev
      </Badge>
    );
  }

  if (type === "ArticleCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <ReaderIcon className="mr-1 size-4" />
        <span className="hidden whitespace-pre sm:inline">Zengin </span>
        Metin
      </Badge>
    );
  }

  if (type === "DocumentCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <DownloadIcon className="mr-1 size-4" />
        Belge
      </Badge>
    );
  }

  if (type === "EmbedCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <CodeIcon className="mr-1 size-4" />
        Embed
      </Badge>
    );
  }

  if (type === "LiveCourseItem") {
    return (
      <Badge variant="outline" className={className}>
        <RadioIcon className="mr-1 size-4" />
        Canlı
        <span className="hidden whitespace-pre sm:inline"> Ders</span>
      </Badge>
    );
  }

  return null;
};

export default ItemTypeBadge;
