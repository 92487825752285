import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { useUpdateItemProgressMutation } from "@generated/useUpdateItemProgressMutation.graphql";

const useUpdateItemProgress = () => {
  const [commitUpdateItemProgress, isInFlight] =
    useMutation<useUpdateItemProgressMutation>(graphql`
      mutation useUpdateItemProgressMutation(
        $input: UpdateCourseItemProgressInput!
      ) {
        updateCourseItemProgress(input: $input) {
          progress {
            progress
            status
            firstProgressAt
            updatedAt
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const updateItemProgress = useCallback(
    (args: UseMutationConfig<useUpdateItemProgressMutation>) => {
      return commitUpdateItemProgress({
        ...args,
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.updateCourseItemProgress.progress === null ||
            mutationData.updateCourseItemProgress.progress === undefined
          ) {
            return;
          }

          const courseItem = store.get(args.variables.input.itemId);
          if (courseItem === null || courseItem === undefined) {
            return;
          }

          const progressRecord =
            courseItem.getLinkedRecord("viewerProgress") ??
            store.create(
              `client:${args.variables.input.itemId}:viewerProgress`,
              "CourseItemProgress",
            );

          progressRecord.setValue(
            mutationData.updateCourseItemProgress.progress.progress,
            "progress",
          );
          progressRecord.setValue(
            mutationData.updateCourseItemProgress.progress.status,
            "status",
          );
          progressRecord.setValue(
            mutationData.updateCourseItemProgress.progress.firstProgressAt,
            "firstProgressAt",
          );
          progressRecord.setValue(
            mutationData.updateCourseItemProgress.progress.updatedAt,
            "updatedAt",
          );

          courseItem.setLinkedRecord(progressRecord, "viewerProgress");
        },
      });
    },
    [commitUpdateItemProgress],
  );

  return [updateItemProgress, isInFlight] as const;
};

export default useUpdateItemProgress;
