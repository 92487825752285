"use client";

import { createElement, useEffect, useRef } from "react";
import React from "react";

const DangerouslySetHtmlContent: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    html: string;
    allowRerender?: boolean;
  }
> = ({ html, dangerouslySetInnerHTML, allowRerender, ...rest }) => {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null");
    if (!isFirstRender.current) return;
    isFirstRender.current = Boolean(allowRerender);

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ""; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html, divRef]);

  return createElement("div", { ...rest, ref: divRef });
};

export default DangerouslySetHtmlContent;
