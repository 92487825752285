"use client";

import React, { useMemo } from "react";
import { isUrl } from "./utils/isUrl";
import videoParser from "js-video-url-parser";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { cn } from "@/lib/utils";
import { Tweet } from "react-tweet";
import DangerouslySetHtmlContent from "@/components/DangerouslySetHtmlContent";

// https://github.com/Zod-/jsVideoUrlParser/issues/112
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
videoParser.plugins.youtube.parseVideoUrl = function (url) {
  let match = url.match(
    /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
  );
  if (match) {
    return match[1];
  }
  // match youtube shorts
  match = url.match(/youtube.com\/shorts\/([\w-]{11})/i);
  if (match) {
    return match[1];
  }
  return undefined;
};

interface Props {
  url: string | null;
  customHtml?: string | null;
  className?: string;
  style?: React.CSSProperties;
}

const Embed: React.FC<Props> = ({ url, customHtml, className, style }) => {
  const { embed, isVideo } = useMediaState({
    url: url ?? "",
    urlParsers: [parseSupportedUrls, parseVideoUrl],
  });

  const provider = embed?.provider;

  if (customHtml) {
    return (
      <DangerouslySetHtmlContent
        className={cn("group relative m-0", className)}
        contentEditable={false}
        html={customHtml}
        style={style}
      />
    );
  }

  return (
    <figure
      className={cn("group relative m-0 w-full", className)}
      contentEditable={false}
    >
      {isVideo ? (
        provider === "youtube" ? (
          <LiteYouTubeEmbed
            id={embed!.id!}
            title="youtube"
            wrapperClass={cn(
              "rounded-sm",
              "relative block cursor-pointer bg-black bg-cover bg-center [contain:content]",
              "[&.lyt-activated]:before:absolute [&.lyt-activated]:before:top-0 [&.lyt-activated]:before:h-[60px] [&.lyt-activated]:before:w-full [&.lyt-activated]:before:bg-top [&.lyt-activated]:before:bg-repeat-x [&.lyt-activated]:before:pb-[50px] [&.lyt-activated]:before:[transition:all_0.2s_cubic-bezier(0,_0,_0.2,_1)]",
              "[&.lyt-activated]:before:bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==)]",
              'after:block after:pb-[var(--aspect-ratio)] after:content-[""]',
              "[&_>_iframe]:absolute [&_>_iframe]:left-0 [&_>_iframe]:top-0 [&_>_iframe]:h-full [&_>_iframe]:w-full",
              "[&_>_.lty-playbtn]:z-[1] [&_>_.lty-playbtn]:h-[46px] [&_>_.lty-playbtn]:w-[70px] [&_>_.lty-playbtn]:rounded-[14%] [&_>_.lty-playbtn]:bg-[#212121] [&_>_.lty-playbtn]:opacity-80 [&_>_.lty-playbtn]:[transition:all_0.2s_cubic-bezier(0,_0,_0.2,_1)]",
              "[&:hover_>_.lty-playbtn]:bg-[red] [&:hover_>_.lty-playbtn]:opacity-100",
              '[&_>_.lty-playbtn]:before:border-y-[11px] [&_>_.lty-playbtn]:before:border-l-[19px] [&_>_.lty-playbtn]:before:border-r-0 [&_>_.lty-playbtn]:before:border-[transparent_transparent_transparent_#fff] [&_>_.lty-playbtn]:before:content-[""]',
              "[&_>_.lty-playbtn]:absolute [&_>_.lty-playbtn]:left-1/2 [&_>_.lty-playbtn]:top-1/2 [&_>_.lty-playbtn]:[transform:translate3d(-50%,-50%,0)]",
              "[&_>_.lty-playbtn]:before:absolute [&_>_.lty-playbtn]:before:left-1/2 [&_>_.lty-playbtn]:before:top-1/2 [&_>_.lty-playbtn]:before:[transform:translate3d(-50%,-50%,0)]",
              "[&.lyt-activated]:cursor-[unset]",
              "[&.lyt-activated]:before:pointer-events-none [&.lyt-activated]:before:opacity-0",
              "[&.lyt-activated_>_.lty-playbtn]:pointer-events-none [&.lyt-activated_>_.lty-playbtn]:!opacity-0",
            )}
          />
        ) : (
          <div
            className={cn(
              provider === "vimeo"
                ? "pb-[75%]"
                : provider === "youku"
                  ? "pb-[56.25%]"
                  : provider === "dailymotion"
                    ? "pb-[56.0417%]"
                    : provider === "coub"
                      ? "pb-[51.25%]"
                      : provider === "tiktok"
                        ? "h-[740px] w-full"
                        : "pb-[56.25%]",
            )}
          >
            <iframe
              className={cn(
                "absolute left-0 top-0 h-full w-full rounded-sm",
                isVideo && "border-0",
              )}
              src={embed!.url}
              title="embed"
              allowFullScreen
            />
          </div>
        )
      ) : provider === "twitter" ? (
        <div className={cn("[&_.react-tweet-theme]:my-0")}>
          <Tweet id={embed!.id!} />
        </div>
      ) : provider === "spotify" ? (
        <div className="h-[352px] w-full">
          <iframe
            className="absolute left-0 top-0 size-full rounded-sm"
            src={embed!.url}
            title="embed"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        </div>
      ) : (
        <div className="pb-[56.25%]">
          <iframe
            className="absolute left-0 top-0 size-full rounded-sm"
            src={embed!.url}
            title="embed"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        </div>
      )}
    </figure>
  );
};

export type EmbedUrlData = {
  url?: string;
  provider?: string;
  id?: string;
};

export type EmbedUrlParser = (url: string) => EmbedUrlData | undefined;

const VIDEO_PROVIDERS = [
  "youtube",
  "vimeo",
  "dailymotion",
  "youku",
  "coub",
  "tiktok",
];

const useMediaState = ({
  url,
  urlParsers,
}: {
  url: string;
  urlParsers?: EmbedUrlParser[];
}): {
  embed: EmbedUrlData | undefined;
  isVideo: boolean;
} => {
  const embed = useMemo(() => {
    if (!urlParsers) return;

    for (const parser of urlParsers) {
      const data = parser(url);
      if (data) {
        return data;
      }
    }

    return {
      url,
    };
  }, [urlParsers, url]);

  const isVideo =
    !!(embed && "provider" in embed && embed?.provider) &&
    VIDEO_PROVIDERS.includes(embed.provider);

  return {
    embed,
    isVideo,
  };
};

const YOUTUBE_PREFIX = "https://www.youtube.com/embed/";
const VIMEO_PREFIX = "https://player.vimeo.com/video/";
const DAILYMOTION_PREFIX = "https://www.dailymotion.com/embed/video/";
const YOUKU_PREFIX = "https://player.youku.com/embed/";
const COUB_PREFIX = "https://coub.com/embed/";
const TIKTOK_PREFIX = "https://www.tiktok.com/embed/v2/";

const parseVideoUrl = (url: string): EmbedUrlData | undefined => {
  if (!isUrl(url)) return;

  const videoData = videoParser.parse(url);

  if (videoData?.provider && videoData.id) {
    const { id, provider } = videoData;

    const providerUrls: Record<string, string> = {
      youtube: `${YOUTUBE_PREFIX}${id}`,
      vimeo: `${VIMEO_PREFIX}${id}`,
      dailymotion: `${DAILYMOTION_PREFIX}${id}`,
      youku: `${YOUKU_PREFIX}${id}`,
      coub: `${COUB_PREFIX}${id}`,
      tiktok: `${TIKTOK_PREFIX}${id}`,
    };

    return {
      id,
      provider,
      url: providerUrls[provider] ?? url,
    };
  }
};

const SPOTIFY_PREFIX = "https://open.spotify.com/embed/";

const spotifyRegex =
  /^https?:\/\/open\.spotify\.com\/(embed\/)?(?<type>track|album|playlist)\/(?<id>[a-zA-Z0-9]+)/;

const twitterRegex =
  /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(?<id>\d+)/;

const parseSupportedUrls = (url: string): EmbedUrlData | undefined => {
  if (!isUrl(url)) return;

  if (url.match(twitterRegex)) {
    return {
      provider: "twitter",
      id: twitterRegex.exec(url)?.groups?.id,
      url,
    };
  }

  if (url.match(spotifyRegex)) {
    const { type, id } = spotifyRegex.exec(url)?.groups ?? {};
    return {
      provider: "spotify",
      id,
      url: `${SPOTIFY_PREFIX}${type}/${id}`,
    };
  }
};

export default Embed;
