/**
 * @generated SignedSource<<57eb28d4b687242675f843a19b81dff6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type UpdateAttachmentInput = {
  attachmentId: string;
  fileName?: AttachmentFileNameInput | null | undefined;
  title?: AttachmentTitleInput | null | undefined;
};
export type AttachmentTitleInput = {
  value: string;
};
export type AttachmentFileNameInput = {
  value: string;
};
export type UpdateAttachmentDialogUpdateAttachmentMutation$variables = {
  input: UpdateAttachmentInput;
};
export type UpdateAttachmentDialogUpdateAttachmentMutation$data = {
  readonly updateAttachment: {
    readonly attachment: {
      readonly " $fragmentSpreads": FragmentRefs<"CourseAdminAttachment_attachment">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type UpdateAttachmentDialogUpdateAttachmentMutation = {
  response: UpdateAttachmentDialogUpdateAttachmentMutation$data;
  variables: UpdateAttachmentDialogUpdateAttachmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAttachmentDialogUpdateAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAttachmentPayload",
        "kind": "LinkedField",
        "name": "updateAttachment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachment",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CourseAdminAttachment_attachment"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAttachmentDialogUpdateAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAttachmentPayload",
        "kind": "LinkedField",
        "name": "updateAttachment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileSize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46c10c6c6366c1d7fd520cb90f014886",
    "id": null,
    "metadata": {},
    "name": "UpdateAttachmentDialogUpdateAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAttachmentDialogUpdateAttachmentMutation(\n  $input: UpdateAttachmentInput!\n) {\n  updateAttachment(input: $input) {\n    attachment {\n      ...CourseAdminAttachment_attachment\n      id\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment CourseAdminAttachment_attachment on Attachment {\n  id\n  title\n  createdAt\n  fileName\n  fileSize\n  fileType\n  ...UpdateAttachmentDialog_attachment\n  ...DeleteCourseItemAttachmentDialogContent_attachment\n}\n\nfragment DeleteCourseItemAttachmentDialogContent_attachment on Attachment {\n  id\n  title\n}\n\nfragment UpdateAttachmentDialog_attachment on Attachment {\n  id\n  title\n  fileName\n}\n"
  }
};
})();

(node as any).hash = "fe8791c734cecc4e1d031344ac8daecd";

export default node;
